@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-out;
}

body {
  background-color: #F5F8FF;
  @apply text-blue-900;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  @apply text-blue-700 hover:text-blue-300 transition-colors underline;
}

.hl-strip:before {
  @apply h-4 bg-gradient-to-r from-green-500 to-green-600;
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.hl-button {
  @apply bg-gradient-to-r from-green-500 to-green-600 text-white font-bold h-12 px-5 text-base rounded-xl leading-tight;
}

@media screen(md) {
  .hl-button {
    @apply text-lg;
  }
}

.hl-button[disabled] {
  @apply from-gray-300 to-gray-400 text-gray-500 cursor-not-allowed;
}

.hl-button-lg {
  @apply hl-button w-80 h-16 rounded-2xl shadow-md;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.hl-button.hl-gradient-red, .hl-button-lg.hl-gradient-red {
  @apply bg-gradient-to-r from-red-500 to-red-600;
}

.hl-input {
  @apply focus:outline-none border-b border-b-border border-solid border-opacity-50 p-2.5 bg-transparent text-lg;
}

.hl-input[disabled] {
  @apply text-gray-500 cursor-not-allowed;
}

.hl-input-error {
  @apply text-red-700 text-sm;
}

.hl-panel {
  @apply bg-white shadow-panel border border-border border-opacity-20 rounded-2xl;
}

.hl-welcome-background {
  background-size: auto auto, 50% 50%, 50% 50%;
  background-position: center bottom 72px, left -60px bottom -180px, right -40px bottom -160px;
  background-repeat: no-repeat, no-repeat, no-repeat;
}

@media screen(lg)  {
  .hl-welcome-background {
    background-size: auto auto, auto auto, auto auto;
    background-position: center bottom 72px, 100px bottom, right bottom 72px;
  }
}

.markdown h1 {
  @apply text-xl font-bold mb-2;
}

.markdown h2 {
  @apply text-lg font-bold mb-2;
}

.markdown h3 {
  @apply text-lg font-semibold mb-1;
}

.markdown h4 {
  @apply font-semibold mb-1;
}

.markdown h5 {
  @apply font-semibold mb-1;
}

.markdown h6 {
  @apply text-sm font-semibold mb-1;
}

.markdown p {
  @apply mb-2;
}

.markdown ul {
  @apply list-disc pl-5 mb-2;
}

.markdown ol {
  @apply list-decimal pl-5 mb-2;
}

.markdown li {
  @apply mb-1;
}

.markdown blockquote {
  @apply border-l-4 border-gray-200 pl-4 italic mb-2;
}

.markdown code {
  @apply bg-gray-100 rounded px-1 font-mono;
}

.markdown pre {
  @apply bg-gray-100 p-3 rounded overflow-x-auto;
}

.markdown a {
  @apply text-blue-600 hover:underline;
}

.markdown img {
  @apply max-w-full h-auto mb-2;
}
